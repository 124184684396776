@if (!disabled) {
  <div>
    @switch (getUiPresentationType()) {
      @case (MetaFormFieldPresentationType.TEXT_INPUT) {
        <div>
          <form [formGroup]="formGroup">
            <div [class.clr-error]="formGroup.invalid">
              <input
                [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
                formControlName="ruleValue"
                type="text"
                class="clr-input standard-input"
              />
              @if (formGroup.get('ruleValue').hasError('minlength')) {
                <span class="clr-subtext">
                  {{ 'ERROR_MESSAGES.MIN_LENGTH' | translate: { n: selectedCondition?.minLength } }}
                </span>
              }
            </div>
          </form>
        </div>
      }
      @case (MetaFormFieldPresentationType.IPV4_INPUT) {
        <div>
          <dpa-ip-value-selector
            [selectedValues]="selectedValues"
            (onChange)="onChange($event)"
          />
        </div>
      }
      @case (MetaFormFieldPresentationType.IPV4_LIST_INPUT) {
        <div>
          <dpa-ip-value-selector
            [selectedValues]="selectedValues"
            [listSelection]="true"
            (onChange)="onChange($event)"
          />
        </div>
      }
      @case (MetaFormFieldPresentationType.IPV4_RANGE_INPUT) {
        <div>
          <dpa-ip-value-selector
            [selectedValues]="selectedValues"
            [rangeSelection]="true"
            (onChange)="onChange($event)"
          />
        </div>
      }
      @case (MetaFormFieldPresentationType.SELECT) {
        <div>
          <dpa-type-ahead
            [placeholderText]="placeholderText"
            [noValueFoundText]="noValueFoundText"
            [selectedItem]="selectedItems[0]"
            [searchableItems]="getSearchableItems()"
            [keyBy]="unwrapValue"
            [customFormatter]="unwrapValue"
            [labelFormatter]="unwrapValue"
            [createCustomItem]="wrapValue"
            [dropdownAlignRight]="dropdownAlignRight"
            [subgroupConfig]="getFuzzySubgroupConfig()"
            [isSuggestionLoading]="isSuggestionLoading$ | async"
            (selectedItemChange)="onSelectedItemsChange($event)"
            (onQueryChange)="onQueryChange($event)"
          />
        </div>
      }
      @case (MetaFormFieldPresentationType.MULTISELECT) {
        <div>
          <dpa-type-ahead-multi
            [placeholderText]="placeholderText"
            [noValueFoundText]="noValueFoundText"
            [selectedItems]="selectedItems"
            [searchableItems]="getSearchableItems()"
            [keyBy]="unwrapValue"
            [searchFormatter]="unwrapValue"
            [labelFormatter]="unwrapValue"
            [useCheckboxes]="false"
            [includeSelectedInResults]="false"
            [createCustomItem]="wrapValue"
            [subgroupConfig]="getFuzzySubgroupConfig()"
            [isSuggestionLoading]="isSuggestionLoading$ | async"
            [dropdownAlignRight]="dropdownAlignRight"
            [maxFilteredItemCount]="autoCompletedItems.length"
            [delimiterSupported]="delimiterSupported"
            (selectedItemsChange)="onSelectedItemsChange($event)"
            (onQueryChange)="onQueryChange($event)"
          />
        </div>
      }
      @case (MetaFormFieldPresentationType.WITHIN) {
        <div>
          <dpa-relative-time-picker
            [relativeTime]="startValue"
            [dropdownAlignRight]="dropdownAlignRight"
            (relativeTimeChange)="onChange($event)"
          />
        </div>
      }
      @default {
        <div>
          @if (column.isTreeField()) {
            <dpa-organization-group-selector
              [selectedValues]="selectedValues"
              [singleSelection]="filterRule.condition === FILTER_CONDITION.contains"
              [orgHierarchy]="orgHierachy$ | async"
              [dropdownAlignRight]="dropdownAlignRight"
              (onDone)="onOrganizationGroupSelection($event)"
            />
          }
          @if (column.dataType === DATA_TYPE[DATA_TYPE.BOOLEAN]) {
            <div>
              <dpa-type-ahead
                [placeholderText]="placeholderText"
                [selectedItem]="selectedValue"
                [searchableItems]="booleanItems"
                (selectedItemChange)="onChange($event)"
              />
            </div>
          }
          @if (column.dataType === DATA_TYPE[DATA_TYPE.STRING]) {
            <input
              [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
              [ngModel]="selectedValue"
              (ngModelChange)="onChange($event)"
              type="text"
              class="clr-input standard-input"
            />
          }
          @if (isNumber(column.dataType)) {
            <div class="input-container">
              @if (filterRule.condition !== FILTER_CONDITION.includes) {
                <input
                  [step]="getSupportedStep(column.dataType)"
                  [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
                  [ngModel]="startValue"
                  (ngModelChange)="onChange($event)"
                  (keydown)="onKeyDownForCheckingInputForIntegerDataType($event)"
                  (paste)="onPasteForCheckingInputForIntegerDataType($event)"
                  type="number"
                  class="clr-input standard-input"
                />
                @if (filterRule.condition === FILTER_CONDITION.between || filterRule.condition === FILTER_CONDITION.notBetween) {
                  <span class="p2">&amp;</span>
                  <input
                    [step]="getSupportedStep(column.dataType)"
                    [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
                    [ngModel]="endValue"
                    (ngModelChange)="onChange([startValue, $event])"
                    type="number"
                    class="clr-input standard-input"
                  />
                }
              } @else {
                <dpa-multi-number-selector
                  [selectedValues]="selectedValues"
                  (onChange)="onChange($event)"
                  (keydown)="onKeyDownForCheckingInputForIntegerDataType($event)"
                  (paste)="onPasteForCheckingInputForIntegerDataType($event)"
                  class="standard-input"
                />
              }
            </div>
          }
          @if (column.isDateTimeField()) {
            <section class="input-container">
              <dpa-date-time-picker
                [date]="getDateFromTimestamp(startDate)"
                [title]="getDateTimePickerTitle('start')"
                [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME"
                (onSelectDate)="onStartDateChange($event)"
              />
              @if ([FILTER_CONDITION.between, FILTER_CONDITION.notBetween].includes(filterRule.condition)) {
                <span class="p2">&amp;</span>
                <dpa-date-time-picker
                  [date]="getDateFromTimestamp(endDate)"
                  [title]="getDateTimePickerTitle('end')"
                  [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME"
                  [popupAlignRight]="true"
                  (onSelectDate)="onEndDateChange($event)"
                />
              }
            </section>
          }
        </div>
      }
    }
    <ng-container *ngTemplateOutlet="maxLengthError" />
  </div>
} @else {
  <input
    [disabled]="true"
    [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
    type="text"
    class="clr-input standard-input"
  />
}
<ng-template #maxLengthError>
  <div class="clr-error">
    @if (isQueryMaxInvalid) {
      <span class="clr-subtext">
        {{ 'ERROR_MESSAGES.MAX_LENGTH' | translate: { n: MAX_TEXT_LENGTH } }}
      </span>
    }
  </div>
</ng-template>
